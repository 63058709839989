import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import React, { useContext, useEffect } from 'react';

import CanonicalURL from '@/components/common/Canonical';
import HeroStoreSearchBanner from '@/components/hero-banner/hero-storesearch-banner';
import { getSEOProps } from '@/components/utility';
import HeaderFooterContext from '@/context/HeaderFooterContext';
import MapCordinateContext from '@/context/MapCordinateContext';
import {
  getHeaderAndFooterDetailsWithDefaultLocale,
  getHomeBodyDetailsWithDefaultLocale,
} from '@/services/home';
import { BRAND_NAME_EN, BRAND_NAME_FR } from '@/utils/constants';

const CommunityComponent = dynamic(
  () => import('@/components/home-content/community')
);
const PartnerComponent = dynamic(
  () => import('@/components/home-content/partners')
);
const BrandComponent = dynamic(() => import('@/components/ourBrands'));
const SafeStorageSolutions = dynamic(
  () => import('@/components/home-content/safe-storage-solutions')
);
const ClientReviews = dynamic(
  () => import('@/components/home-content/client-reviews')
);

const FindTheRightUnits = dynamic(
  () => import('@/components/home-content/find-the-right-units')
);
const ContactFreeRentals = dynamic(
  () => import('@/components/home-content/contact-free-rentals')
);

const ConvenientLocations = dynamic(
  () => import('@/components/convenient-locations')
);

const Home: NextPage = (props: any) => {
  const values = useContext(HeaderFooterContext);
  const router = useRouter();
  const seoProps = getSEOProps(
    props?.bodyData?.data[0]?.attributes?.SEO,
    router
  );
  console.log(seoProps?.openGraph?.images)
  const { setLatLog, setLatLangs, setLatLogCity } = useContext(MapCordinateContext);

  values.setHeaderFooterData(props?.headerFooterData?.data);

  useEffect(() => {
    setLatLog({});
    setLatLogCity({});
    setLatLangs({});
    if (sessionStorage.getItem('facCords')) {
      sessionStorage.removeItem('facCords');
    }
  }, []);

  useEffect(() => {

  }, []);

  return (
    <>
      <NextSeo {...seoProps} />
      <CanonicalURL />
      <HeroStoreSearchBanner
        isBread={false}
        cmsData={
          props?.bodyData?.data &&
          props?.bodyData?.data.length &&
          props?.bodyData?.data[0]
        }
      />
      <ConvenientLocations display={true}></ConvenientLocations>
      <SafeStorageSolutions
        title={props?.bodyData?.data[0]?.attributes?.Section[2]?.Title}
        cards={props?.bodyData?.data[0]?.attributes?.Section[3]?.Cards}
      />
      <SafeStorageSolutions
        title={props?.bodyData?.data[0]?.attributes?.Section[4]?.Title}
        cards={props?.bodyData?.data[0]?.attributes?.Section[5]?.Cards}
      />
      <FindTheRightUnits bodyData={props?.bodyData}></FindTheRightUnits>
      <ContactFreeRentals bodyData={props.bodyData}></ContactFreeRentals>

      <ClientReviews></ClientReviews>

      <CommunityComponent bodyData={props?.bodyData}></CommunityComponent>
      <PartnerComponent bodyData={props.bodyData}></PartnerComponent>
      <BrandComponent />
    </>
  );
};

export async function getServerSideProps(props: any) {
  let defaultBrand = BRAND_NAME_EN;
  const locale = props?.locale;
  if (locale === 'fr') defaultBrand = BRAND_NAME_FR;

  const result: any = await Promise.all([
    getHeaderAndFooterDetailsWithDefaultLocale(defaultBrand, locale),
    getHomeBodyDetailsWithDefaultLocale(defaultBrand, locale),
  ]);

  // console.log(
  //   headFooterRes?.request?.method,
  //   'CMS',
  //   '/brands',
  //   headFooterRes?.status,
  //   headFooterRes?.statusText,
  //   headFooterRes?.headers?.date
  // );

  return {
    props: {
      headerFooterData: result[0]?.data,
      bodyData: result[1]?.data,
    },
  };
}

export default Home;
